@import '../../variable.scss';

.admin_view {
  &.content_area {
    padding-left: 0px !important;
  }
  .sub_title {
    font-size: 16px;
    font-weight: 400;
    color: $nevada;
  }
  .tables_view {
    background-color: $white;
    width: 100%;
    height: calc(100vh - 102px);
  }
  .round_border {
    margin-bottom: 0;
    &.pending_queue {
      tr {
        th {
          &:last-child {
            text-align: left;
          }
          &:first-child {
            width: 70px;
            max-width: 70px;
            position: relative;
          }
        }
        td {
          padding-top: 10px;
          vertical-align: middle;
          cursor: pointer;
          &:last-child {
            text-align: left;
          }
          &:first-child {
            width: 70px;
            max-width: 70px;
            position: relative;
          }
          &.case_id {
            font-weight: 600;
            color: $secondary_color;
            cursor: pointer;
          }
          .queue_status {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: inline-block;
            background-color: $concrete;
          }
          .taken_time {
            display: block;
            font-size: 14px;
            line-height: 10px;
          }
        }
        &.disabled {
          color: $primary_border_color;
          pointer-events: none;
          .case_id {
            color: $primary_border_color;
          }
        }
      }
    }
  }
  .CircularProgressbar {
    height: 30px;
    width: 30px;
  }
  .width120 {
    width: 120px;
    float: left;
  }
  .width164 {
    width: 164px;
    float: left;
  }
  .to_separator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    padding-top: 18px;
    margin: 0 8px;
    float: left;
  }
}
